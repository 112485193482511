import request from '@/utils/requestV2'
const qs = require('qs')

// 条件查询资产包合同列表，分页显示
export function getAssetPackageContractPage (data) {
  return request({
    url: '/ooh-scp/v1/assetPackage/contract/getAssetPackageContractPage',
    method: 'post',
    data: qs.stringify(data)
  })
}
