<template>
  <div>
    <Row :gutter="8" class="m-b-5">
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <Select
          size="small"
          v-model="contractCategoryList"
          placeholder="发布类型"
          clearable
          multiple
        >
          <Option
            v-for="(item, index) in contractTypes"
            :key="index"
            :value="item.id"
            >{{ item.name }}</Option
          >
        </Select>
      </i-col>
      <i-col span="4">
        <DatePicker size="small" transfer :show-week-numbers="true" type="daterange" v-model="contractSchedule"
          @on-change="scheduleChange" style="width: 100%" placement="top-start" placeholder="合同签订时间"></DatePicker>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4">
        <i-input
          size="small"
          v-model="query.keyword"
          placeholder="关键字"
        ></i-input>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4" class="m-b-5">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        >

        <!-- <Button
          size="small"
          type="success"
          class="m-l-5"
          @click="handleDownload"
          >导出账单</Button
        > -->
      </i-col>
    </Row>

    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
  </div>
</template>

<script>
import { getAssetPackageContractPage } from '@/api/scp/assetPackage'
import { getcategorylist } from '@/api/scp/scpbusiness'

import { ParseDate, GetDateStr, GetCurrentSchedule } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'

import { formatContractStatus } from '@/utils/tagStatus'
import { toMoney } from '@/utils/wnumb_own'

export default {
  components: { },
  data () {
    return {
      contractSchedule: '',
      contractTypes: [],

      total: 0,
      contractCategoryList: [],
      query: {
        pageNumber: 1,
        pageSize: 15,
        contractCategoryListStr: '',
        keyword: ''
      },
      tableData: [],
      columnManagedata: [
        {
          title: '合同编号',
          key: 'code'
        },
        { title: '广告主', key: 'advertiserName' },
        { title: '品牌', key: 'brandName' },
        { title: '行业', key: 'industryName' },
        {
          title: '起止日期',
          key: 'startDate',
          render: (h, params) => {
            return h('span', GetCurrentSchedule(params.row.startDate, params.row.endDate))
          }
        },
        { title: '签订时间', key: 'lastApprovedTime' },
        { title: '发布类型', key: 'contractCategoryName' },
        { title: '代理商', key: 'companyName' },
        { title: '资源所属业主', key: 'assetPackageName' },

        {
          title: '金额',
          render: (h, params) => {
            return h('div', [h('p', toMoney(params.row.signUsePrice))])
          }
        },
        {
          title: '订单状态',
          align: 'center',
          render: (h, params) => {
            return formatContractStatus(
              h,
              params.row.status,
              params.row.statusName
            )
          }
        }
      ]

    }
  },
  created () {
    getcategorylist({}).then(res => {
      this.contractTypes = res
    })
    this.initPageData()
  },
  methods: {

    scheduleChange () {
      const shcedule = this.contractSchedule
      if (shcedule[0] === '' || shcedule[1] === '') {
        this.query.startDate = ''
        this.query.endDate = ''
      } else {
        this.query.startDate = GetDateStr(shcedule[0], 1)
        this.query.endDate = GetDateStr(shcedule[1], 1)
      }
    },

    initPageData () {
      if (this.contractCategoryList.length) {
        this.query.contractCategoryListStr = JSON.stringify(this.contractCategoryList)
      } else {
        this.query.contractCategoryListStr = ''
      }

      getAssetPackageContractPage(this.query).then((response) => {
        this.total = response.totalRow
        this.tableData = response.list
        this.query.pageNumber = response.pageNumber
      })
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    // CreateCustomer () {
    //   this.$emit('on-create')
    // },
    // handleShowDetail (customerId) {
    //   this.$emit('on-detail', customerId)
    // },

    handleDownload () {
      const filename = '签约列表(' + ParseDate(new Date()) + ').xlsx'

      downloadFileRequest(
        process.env.VUE_APP_API_URL_V2 + '/ooh-order/om/v1/report/download-bill-excel',
        this.query,
        filename
      )
    }
  },
  watch: {
  },
  computed: {
  }
}
</script>
